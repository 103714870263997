import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "../header";
import Sidebar from "../sidebar";
import classes from "./adminLayout.module.scss";
import "./adminBuiltin.scss";

const AdminLayout = () => {
  const [isNavbar, setIsNavbar] = useState(true);
  const [closeNavbar2, setCloseNavbar2] = useState(false);
  const handleArrow = () => {
    setCloseNavbar2(false);
    setIsNavbar(!isNavbar);
  };
  // const handleArrow = () => {
  //   setIsNavbar((prevIsNavbar) => {
  //     const newIsNavbar = !prevIsNavbar;
  //     localStorage.setItem("isNavbar", newIsNavbar); // Save to localStorage
  //     return newIsNavbar;
  //   });
  // };
  // useEffect(() => {
  //   const savedNavbarState = localStorage.getItem("isNavbar");
  //   if (savedNavbarState !== null) {
  //     setIsNavbar(JSON.parse(savedNavbarState));
  //   }
  // }, []);
  return (
    <>
      <div className={classes.mainLayoutSection}>
        <div
          className={`${classes.mainHeader} ${
            isNavbar && classes.removeMarginHead
          }`}
        >
          <Header handleArrow={handleArrow} />
        </div>
        <div className={classes.mainSidebarContent}>
          <div
            className={`${classes.mainSidebar} ${
              isNavbar && classes.openSideBar
            } ${isNavbar && classes.closeSideBar} ${
              isNavbar && window.innerWidth > 1199 && "hideMenuItem"
            }`}
          >
            <Sidebar
              setCloseNavbar2={setCloseNavbar2}
              isNavbar={isNavbar}
              setIsNavbar={setIsNavbar}
              handleArrow={handleArrow}
              closeNavbar2={closeNavbar2}
            />
          </div>
          <div
            className={`${classes.mainContent} ${
              isNavbar && classes.removeMargin
            }`}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLayout;
