import React, { useState, useEffect, forwardRef } from "react";
import { Field, Formik, Form, ErrorMessage } from "formik";
import classes from "./VerifyOTP.module.scss";
import { Link } from "react-router-dom";
import { userResetPassword } from "../../../api/auth";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { LOGIN } from "../../../constants";
import * as Yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import resetBanner from "../../../assets/images/signup5.png";
import logo from "../../../assets/images/logo.png";
import CustomInput from "../../../components/shared/customInput";

const VerifyRegisterOTPSchema = Yup.object().shape({
  otp1: Yup.string()
    .length(1, '- Must be exactly one digit')
    .required('- Required field 1 ')
    .matches(/^[0-9]$/, "- field 1 Must be a digit "),
  otp2: Yup.string()
    .length(1, '- Must be exactly one digit')
    .required('- Required field 2')
    .matches(/^[0-9]$/, "- field 2 Must be a digit "),
  otp3: Yup.string()
    .length(1, '- Must be exactly one digit')
    .required('- Required field 3')
    .matches(/^[0-9]$/, "- field 3 Must be a digit "),
  otp4: Yup.string()
    .length(1, '- Must be exactly one digit')
    .required('- Required field 4')
    .matches(/^[0-9]$/, "- field 4 Must be a digit "),
});

const VerifyRegisterOTP = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [loading, setLoading] = useState(false);

  const handlePasswordToggle = () => {
    setPasswordVisible(!passwordVisible);
  };
  const handleKeyUp = (e, nextField, prevField) => {
    if (e.target.value.length === 1 && nextField) {
      document.getElementById(nextField).focus();
    } else if (e.key === 'Backspace' && !e.target.value && prevField) {
      document.getElementById(prevField).focus();
    }
  };

  return (
    <div className={classes.mainVerifyOTP}>
      <ToastContainer />
      <div className={classes.defaultForm}>
        {/* <Formik
          initialValues={{ otp1: '', otp2: '', otp3: '', otp4: '' }}
          validationSchema={VerifyRegisterOTPSchema}
          onSubmit={async (values, { setSubmitting }) => {
            const otp = values.otp1 + values.otp2 + values.otp3 + values.otp4;
          setTimeout(() => {
            setLoading(true);
            console.log(otp);
            setSubmitting(false);
            navigate("/complete-profile")
          }, 400);
            // setLoading(true);
            // console.log("values:", values)
            // try {
            //   const res = await userResetPassword(values);
            //   if (res?.status == 200) {
            //     dispatch({
            //       type: LOGIN,
            //       payload: res.data,
            //     });
            //     localStorage.setItem("userData", JSON.stringify(res.data));
            //     setTimeout(() => {
            //       setLoading(false);
            //       navigate("/");
            //     }, 2000);
            //   }
            // } catch (error) {
            //   setErrorMsg(error.response.data.error.message);
            // }
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className={classes.formHeader}>
                <img src={logo} alt="logo" />
                <h1>Verify your email</h1>
                <p>Please check our inbox and tap the link in the email  <br/>  we’ve just sent to:  <span className={classes.regEmail}>Uzair32@gmail.com </span>
              </p>
              </div>
              <div className={classes.inputMainOTP}>
                <Field
                  type="number" 
                  name="otp1" 
                  id="otp1" 
                  // maxLength="1"
                  onBlur={handleBlur}
                  onKeyDown={(e) => {
                    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                      e.preventDefault();  // Prevent increment or decrement
                    }
                  }}
                  onChange={(e) => {
                    if (e.target.value.length <= 1) handleChange(e);
                  }}
                  className={`${classes.inputField} ${
                      errors.otp1 && touched.otp1 ? classes.errorMsgLine : ""
                    // errors.email && touched.email ? classes.errorMsgLine : null
                  }`}
                  placeholder="-"
                  onKeyUp={(e) => handleKeyUp(e, 'otp2', null)}
                />
                 <span>-</span>
                 <Field
                  type="number" 
                  name="otp2" 
                  id="otp2" 
                  // maxLength="1"
                  onBlur={handleBlur}
                  onKeyDown={(e) => {
                    if (
                      !/[0-9]/.test(e.key) &&           // Only allow numeric keys (0-9)
                      e.key !== "Backspace" &&          // Allow backspace for corrections
                      e.key !== "Tab" &&                // Allow tab for navigation
                      e.key !== "ArrowLeft" &&          // Allow left arrow key
                      e.key !== "ArrowRight" &&         // Allow right arrow key
                      e.key !== "Delete"                // Allow delete key
                    ) {
                      e.preventDefault();  // Prevent invalid input
                    }
                  }}
                  onChange={(e) => {
                    if (e.target.value.length <= 1) handleChange(e);
                  }}
                  className={`${classes.inputField} ${
                    // errors.email && touched.email ? classes.errorMsgLine : null
                    errors.otp2 && touched.otp2 ? classes.errorMsgLine : ""
                  }`}
                  placeholder="-"
                  onKeyUp={(e) => handleKeyUp(e, 'otp3', 'otp1')}
                />
                 <span>-</span>
                 <Field
                  type="number" 
                  name="otp3" 
                  id="otp3" 
                  // maxLength="1"
                  onBlur={handleBlur}
                  onKeyDown={(e) => {
                    if (
                      !/[0-9]/.test(e.key) &&           // Only allow numeric keys (0-9)
                      e.key !== "Backspace" &&          // Allow backspace for corrections
                      e.key !== "Tab" &&                // Allow tab for navigation
                      e.key !== "ArrowLeft" &&          // Allow left arrow key
                      e.key !== "ArrowRight" &&         // Allow right arrow key
                      e.key !== "Delete"                // Allow delete key
                    ) {
                      e.preventDefault();  // Prevent invalid input
                    }
                  }}
                  // onChange={handleChange}
                  onChange={(e) => {
                    if (e.target.value.length <= 1) handleChange(e);
                  }}
                  className={`${classes.inputField} ${
                    // errors.email && touched.email ? classes.errorMsgLine : null
                    errors.otp3 && touched.otp3 ? classes.errorMsgLine : ""
                  }`}
                  placeholder="-"
                  onKeyUp={(e) => handleKeyUp(e, 'otp4', 'otp2')}
                />
                 <span>-</span>
                 <Field
                  type="number" 
                  name="otp4" 
                  id="otp4" 
                  maxLength="1"
                  onBlur={handleBlur}
                  onKeyDown={(e) => {
                    if (
                      !/[0-9]/.test(e.key) &&           // Only allow numeric keys (0-9)
                      e.key !== "Backspace" &&          // Allow backspace for corrections
                      e.key !== "Tab" &&                // Allow tab for navigation
                      e.key !== "ArrowLeft" &&          // Allow left arrow key
                      e.key !== "ArrowRight" &&         // Allow right arrow key
                      e.key !== "Delete"                // Allow delete key
                    ) {
                      e.preventDefault();  // Prevent invalid input
                    }
                  }}
                  onChange={(e) => {
                    if (e.target.value.length <= 1) handleChange(e);
                  }}
                  className={`${classes.inputField} ${
                    // errors.email && touched.email ? classes.errorMsgLine : null
                    errors.otp4 && touched.otp4 ? classes.errorMsgLine : ""
                  }`}
                  placeholder="-"
                  onKeyUp={(e) => handleKeyUp(e, null, 'otp3')}
                />
          
               
              </div>
              <div className={classes.errormsg}>
              <ErrorMessage name="otp1" component="div" />
              <ErrorMessage name="otp2" component="div" />
              <ErrorMessage name="otp3" component="div" />
              <ErrorMessage name="otp4" component="div" />
            </div>

              <button type="submit">
                {loading ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItem: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress color="inherit" />
                  </Box>
                ) : (
                  "Confirm OTP"
                )}
              </button>
            </Form>
          )}
        </Formik> */}
        <Formik
  initialValues={{ otp1: '', otp2: '', otp3: '', otp4: '' }}
  validationSchema={VerifyRegisterOTPSchema}
  onSubmit={async (values, { setSubmitting }) => {
    const otp = values.otp1 + values.otp2 + values.otp3 + values.otp4;
    setTimeout(() => {
      setLoading(true);
      console.log(otp);
      setSubmitting(false);
      navigate("/complete-profile");
    }, 400);
      // setLoading(true);
            // console.log("values:", values)
            // try {
            //   const res = await userResetPassword(values);
            //   if (res?.status == 200) {
            //     dispatch({
            //       type: LOGIN,
            //       payload: res.data,
            //     });
            //     localStorage.setItem("userData", JSON.stringify(res.data));
            //     setTimeout(() => {
            //       setLoading(false);
            //       navigate("/");
            //     }, 2000);
            //   }
            // } catch (error) {
            //   setErrorMsg(error.response.data.error.message);
            // }
  }}
>
  {({ handleChange, handleBlur, handleSubmit, errors, touched }) => (
    <Form onSubmit={handleSubmit}>
      <div className={classes.formHeader}>
        <img src={logo} alt="logo" />
        <h1>Verify your email</h1>
        <p>
          Please check your inbox and tap the link in the email <br /> we’ve
          just sent to: <span className={classes.regEmail}>Uzair32@gmail.com</span>
        </p>
      </div>

      <div className={classes.inputMainOTP}>
        {/* OTP 1 */}
        <Field
          type="text" 
          name="otp1"
          id="otp1"
          maxLength="1"
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (
              !/[0-9]/.test(e.key) &&
              e.key !== "Backspace" &&
              e.key !== "Tab" &&
              e.key !== "ArrowLeft" &&
              e.key !== "ArrowRight" &&
              e.key !== "Delete"
            ) {
              e.preventDefault();
            }
          }}
          onInput={(e) => {
            e.target.value = e.target.value.replace(/[^0-9]/g, '');  // Remove non-numeric characters
          }}
          onChange={handleChange}
          className={`${classes.inputField} ${
            errors.otp1 && touched.otp1 ? classes.errorMsgLine : ""
          }`}
          placeholder="-"
          onKeyUp={(e) => handleKeyUp(e, "otp2", null)}
        />
        {/* <span>-</span> */}

        {/* OTP 2 */}
        <Field
          type="text" 
          name="otp2"
          id="otp2"
          maxLength="1"
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (
              !/[0-9]/.test(e.key) &&
              e.key !== "Backspace" &&
              e.key !== "Tab" &&
              e.key !== "ArrowLeft" &&
              e.key !== "ArrowRight" &&
              e.key !== "Delete"
            ) {
              e.preventDefault();
            }
          }}
          onInput={(e) => {
            e.target.value = e.target.value.replace(/[^0-9]/g, '');  // Remove non-numeric characters
          }}
          onChange={handleChange}
          className={`${classes.inputField} ${
            errors.otp2 && touched.otp2 ? classes.errorMsgLine : ""
          }`}
          placeholder="-"
          onKeyUp={(e) => handleKeyUp(e, "otp3", "otp1")}
        />
        {/* <span>-</span> */}

        {/* OTP 3 */}
        <Field
          type="text" 
          name="otp3"
          id="otp3"
          maxLength="1"
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (
              !/[0-9]/.test(e.key) &&
              e.key !== "Backspace" &&
              e.key !== "Tab" &&
              e.key !== "ArrowLeft" &&
              e.key !== "ArrowRight" &&
              e.key !== "Delete"
            ) {
              e.preventDefault();
            }
          }}
          onInput={(e) => {
            e.target.value = e.target.value.replace(/[^0-9]/g, '');  // Remove non-numeric characters
          }}
          onChange={handleChange}
          className={`${classes.inputField} ${
            errors.otp3 && touched.otp3 ? classes.errorMsgLine : ""
          }`}
          placeholder="-"
          onKeyUp={(e) => handleKeyUp(e, "otp4", "otp2")}
        />
        {/* <span>-</span> */}

        {/* OTP 4 */}
        <Field
          type="text" 
          name="otp4"
          id="otp4"
          maxLength="1"
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (
              !/[0-9]/.test(e.key) &&
              e.key !== "Backspace" &&
              e.key !== "Tab" &&
              e.key !== "ArrowLeft" &&
              e.key !== "ArrowRight" &&
              e.key !== "Delete"
            ) {
              e.preventDefault();
            }
          }}
          onInput={(e) => {
            e.target.value = e.target.value.replace(/[^0-9]/g, '');  // Remove non-numeric characters
          }}
          onChange={handleChange}
          className={`${classes.inputField} ${
            errors.otp4 && touched.otp4 ? classes.errorMsgLine : ""
          }`}
          placeholder="-"
          onKeyUp={(e) => handleKeyUp(e, null, "otp3")}
        />
      </div>

      <div className={classes.errormsg}>
        <ErrorMessage name="otp1" component="div" />
        <ErrorMessage name="otp2" component="div" />
        <ErrorMessage name="otp3" component="div" />
        <ErrorMessage name="otp4" component="div" />
      </div>

      <button type="submit">
        {loading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          "Confirm OTP"
        )}
      </button>
    </Form>
  )}
</Formik>

      </div>
      <div className={classes.rightBanner}>
        <div className={classes.bannerHeading}>
          Increase sales <br /> with Foodie!
        </div>
        <img src={resetBanner} alt="reset banner" height="100%" />
      </div>
    </div>
  );
};

export default VerifyRegisterOTP;
