import React, { useEffect, useState } from "react";
import classes from "./tableFilter.module.scss";
import "./tableFilterBuiltin.scss";
import exportButton from "../../../assets/images/sajiha/export.png";
import SearchIcon from "@mui/icons-material/Search";
import { ExpandMore } from "@mui/icons-material";
import Button from "../customBtn";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Checkbox, ListItemText, Menu } from "@mui/material";

const TableFilter = ({
  heading,
  tableLength,
  searchPlaceholder,
  handleSearch,
  handleModalOpenLeft,
  handleModalOpenRight,
  isDropdown,
  buttonTextLeft,
  buttonTextRight,
  datePicker,
  iconBtnLeft,
  iconBtnRight,
  exportBtn,
  handleExport,
  filterDrop,
  handleFilterDrop,
  dropValue,
  handleButtonClick,
  showDropdown,
  selectedValue,
  handleDropdownChange,
  anchorEl,
}) => {
  return (
    <>
      <div className={classes.headerContainerTableFilter}>
        <div className={classes.filterFirstCol}>
          <div className={classes.tableName}>
            {heading && <h2>{heading}</h2>}
            {tableLength &&
              <h5>Total {tableLength}</h5>
            }
          </div>
          {handleSearch &&
            <div className={classes.searchInputContainer}>
              <SearchIcon className={classes.searchIcon} />
              <input
                type="text"
                placeholder={searchPlaceholder}
                onChange={handleSearch}
                className={classes.searchInput}
              />
            </div>
          }
        </div>
        <div className={classes.filterSecondCol}>
          {iconBtnLeft &&
            <Button
              customClass={classes.filterBtn}
              fontsize="0.75rem"
              
              onClick={handleModalOpenLeft}
            >
              <AddBoxIcon /> {buttonTextLeft}
            </Button>
          }
          {iconBtnRight &&
            <Button
              customClass={classes.filterBtn}
              fontsize="0.75rem"
              onClick={isDropdown ? handleButtonClick : handleModalOpenRight} // Conditionally use the correct handler
            >
              {buttonTextRight} <ExpandMore />
            </Button>
          }
           {/* Dropdown menu for comments */}
           {isDropdown && (
            <Menu
              anchorEl={anchorEl}
              open={showDropdown}
              onClose={handleDropdownChange}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <div className="parentComments">  
              <MenuItem
                value="Show Comments"
                selected={selectedValue === 'Show Comments'}
                onClick={() => handleDropdownChange({ target: { value: 'Show Comments' } })}
              >              
                <Checkbox checked={selectedValue === 'Show Comments'}  />
                <ListItemText primary="Show Comments" />

              </MenuItem>
              <MenuItem
                value="Hide Comments"
                selected={selectedValue === 'Hide Comments'}
                onClick={() => handleDropdownChange({ target: { value: 'Hide Comments' } })}
              >
                <Checkbox checked={selectedValue === 'Hide Comments'} />
                <ListItemText primary="Hide Comments" />
              </MenuItem>
              </div>

            </Menu>
          )}
          {datePicker &&
            <div className={`customDatePicker`}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    renderInput={(params) => <TextField {...params} />}
                  />
                </DemoContainer>
              </LocalizationProvider>
              <span>To</span>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    renderInput={(params) => <TextField {...params} />}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          }
          {exportBtn &&
            <Button customClass={classes.filterBtn} lightOrange onClick={handleExport}>
              <img src={exportButton} alt={buttonTextLeft} /> {buttonTextLeft}
            </Button>
          }
          {filterDrop &&
            <div className={classes.filterinput}>
              <h1>Filter by </h1>
              <div className={`${classes.filterSelector} filtertable`}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">All</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={dropValue}
                    label="All"
                    onChange={handleFilterDrop}
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default TableFilter;
