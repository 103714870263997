import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import classes from "./table.module.scss";
import "./tableBuiltin.scss";
import profile from "../../../assets/images/icon.svg";
import deleteIcon from "../../../assets/images/del.svg";
import PaginationComponent from "../tablepagination";
import password from "../../../assets/images/pass.svg";
import edit from "../../../assets/images/edit.svg";
import dollar from "../../../assets/images/dollar.svg";
import Button from "../customBtn";
import branchimage from "../../../assets/images/branchimage.svg";

const ReusableTable = ({
  columns,
  rowPerPage = "5",
  isStatus,
  isCustName,
  CreatedAt,
  isPayment,
  actionButton,
  pagination,
  handleDelete,
  handleEdit,
  handlePassword,
  searchTerm,
  data,
  handleReport,
}) => {
  const style = {
    borderBottom: "none !important",
  };
  const [totalEntries, setTotalEntries] = useState(``);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowPerPage);

  useEffect(() => {
    // Reset the current page to the first page whenever the search term changes
    setPage(0);
  }, [searchTerm]);

  const filteredCustomers =
    searchTerm.length > 2
      ? data.filter((customer) =>
          customer?.name?.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : data;
  const currentPage = page + 1;
  const totalPages = Math.ceil(filteredCustomers.length / rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const currentData = filteredCustomers.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  useEffect(() => {
    if (filteredCustomers.length === 0) {
      setTotalEntries(`Showing 0 to 0 of 0 entries`);
    } else if (searchTerm.length > 2) {
      const searchCount = filteredCustomers.length;
      setTotalEntries(
        `Showing ${currentPage} to ${totalPages} of ${searchCount} entries`
      );
    } else {
      setTotalEntries(
        `Showing ${currentPage} to ${totalPages} of ${rowsPerPage} entries`
      );
    }
  }, [filteredCustomers, searchTerm, currentPage, totalPages, rowsPerPage]);

  const datas = currentData.map((customer, index) => ({
    ...customer,
    index: page * rowsPerPage + index + 1,
  }));

  return (
    <>
      <div className={`${classes.wrapperContainer} mainTableStyle`}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={index} className={classes.columnHeader}>
                  {column.label}
                </TableCell>
              ))}
              {actionButton && (
                <TableCell className={classes.columnHeader}>Actions</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {currentData.length === 0 ? (
              <TableRow>
                <TableCell
                  sx={style}
                  colSpan={columns.length}
                  className={classes.noRecordsCell}
                >
                  No records found
                </TableCell>
              </TableRow>
            ) : (
              datas.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {columns?.map((column, colIndex) => (
                    <TableCell
                      key={colIndex}
                      sx={style}
                      className={classes.cell}
                    >
                      {column.field === "name" ? (
                        <div className={classes.imagecontent}>
                          <img
                            src={profile}
                            className={classes.customerImage}
                            alt="Profile"
                          />
                          {row[column.field]}
                        </div>
                      ) : column.field === "status" && isStatus ? (
                        <span
                          className={`custBage ${row[column.field]?.replace(
                            /\s+/g,
                            ""
                          )}`}
                        >
                          {row[column.field]}
                        </span>
                      ) : column.field === "customer_name" && isCustName ? (
                        <div className={classes.imagecontent}>
                          <img
                            src={profile}
                            className={classes.customerImage}
                            alt="Profile"
                          />
                          {row[column.field]}
                        </div>
                      ) : column.field === "payment" && isPayment ? (
                        <div className={classes.imagecontent}>
                          <img
                            src={dollar}
                            className={classes.customerImage}
                            alt="dollar"
                          />
                          {row[column.field]}
                        </div>
                      ) : column.field === "Created_at" && CreatedAt ? (
                        <div className={classes.imagecontent}>
                          <img src={branchimage} alt="branchimage" />
                        </div>
                      ) : (
                        row[column.field]
                      )}
                    </TableCell>
                  ))}
                  {actionButton && (
                    <TableCell sx={style} className={classes.cell}>
                      <div className={classes.actionBtn}>
                        {handleEdit && (
                          <div
                            className={classes.edit}
                            onClick={() => handleEdit(row?.id)}
                          >
                            <img src={edit} alt="edit" />
                          </div>
                        )}
                        {handlePassword && (
                          <div
                            className={classes.password}
                            onClick={() => handlePassword(row?.id)}
                          >
                            <img src={password} alt="password" />
                          </div>
                        )}
                        {handleDelete && (
                          <div
                            className={classes.deletecontent}
                            onClick={() => handleDelete(row?.id)}
                          >
                            <img src={deleteIcon} alt="Delete" />
                          </div>
                        )}
                        {handleReport && (
                          <Button
                            colorOrange
                            grey
                            customClass={classes.viewDetail}
                            onClick={() => handleReport(row?.id)}
                          >
                            View Detail
                          </Button>
                        )}
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </div>
      {pagination && (
        <div className={classes.test}>
          <div className={classes.totalEntries}>{totalEntries}</div>
          <div style={{ margin: "0 auto" }}>
            <PaginationComponent
              page={page}
              totalPages={totalPages}
              onPageChange={handleChangePage}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ReusableTable;
