import React, { useState, useEffect, Fragment } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Link } from "react-router-dom";
import menuData from "./routes";
import "./sidebarBuiltin.scss";
import classes from "./sidebar.module.scss";
import profileimage from "../../assets/images/profileImage.svg";
import logout from "../../assets/images/logout.svg";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../shared/customBtn";
import chef from "../../assets/images/chef.svg";
import logo from "../../assets/images/logo.png";
import logoF from "../../assets/images/logoF.svg";
import arrowLeft from "../../assets/images/sidebarArrowLeft.svg";
import arrowRight from "../../assets/images/sidebarArrowRight.svg";

const drawerWidth = 240;

const Sidebar = ({
  setCloseNavbar2,
  setIsNavbar,
  handleArrow,
  isNavbar,
  closeNavbar2,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const userRole = localStorage.getItem("userRole");
  let pathSegments = location.pathname.split("/").filter(Boolean);
  let itemLink = pathSegments.length > 0 ? `/${pathSegments[0]}` : "/dashboard";
  let dropLink = `${pathSegments[0]}`;
  let dropItemLink = `/${pathSegments[0]}/${pathSegments[1]}`;

  const [openDropdown, setOpenDropdown] = useState(null);
  const [activeOption, setActiveOption] = useState(itemLink);
  const [activeOptionDrop, setActiveOptionDrop] = useState(dropLink);
  const [activeOptionDropItem, setActiveOptionDropItem] =
    useState(dropItemLink);
  // useEffect(() => {
  //   const savedIsNavbar = localStorage.getItem("isNavbar") === "true";
  //   setIsNavbar(savedIsNavbar);
  // }, [setIsNavbar]);
  useEffect(() => {
    setActiveOption(
      pathSegments.length > 0 ? `/${pathSegments[0]}` : "/dashboard"
    );
    setActiveOptionDrop(`${pathSegments[0]}`);
    setActiveOptionDropItem(`/${pathSegments[0]}/${pathSegments[1]}`);
  }, [location]);

  // const handleClick = (dropdownId) => {
  //   setOpenDropdown((prevOpenDropdown) =>
  //     prevOpenDropdown === dropdownId ? null : dropdownId
  //   );
  // };
  const handleClick = (dropdownId) => {
    // if (window.innerWidth <= 768 && !isNavbar) {
    //   setIsNavbar(true); // Temporarily expand the sidebar on small screens
    // }
    setIsNavbar(false);
    setOpenDropdown((prevOpenDropdown) =>
      prevOpenDropdown === dropdownId ? null : dropdownId
    );
  };

  const handleItemClick = () => {
    setOpenDropdown(null);
    setActiveOptionDrop(null);
    setActiveOptionDropItem(null);
    setCloseNavbar2(true);
    // console.log(isNavbar, "Hello")
    setIsNavbar(true);
  };

  const handleOptionClick = (option) => {
    setActiveOption(option);
  };

  const handleOptionDropClick = (option) => {
    const ada = option.toLowerCase();
    setActiveOptionDrop(ada);
    setIsNavbar(true);
  };
  const handleOptionDropClickItem = (option) => {
    setActiveOptionDropItem(option);
    setActiveOption(null);
    // setCloseNavbar2(true);
    // if (window.innerWidth > 768) {
    setCloseNavbar2(true);
    // }
    setIsNavbar(true);
  };

  // const handleLogout = () => {
  //   navigate("./sign-in");
  //   localStorage.removeItem("authToken");
  //   localStorage.removeItem("userRole");
  //   console.log("logout");
  // };
  const handleMenu = () => {
    navigate("/menu-management");
  };
  return (
    <>
      <div
        className={`${classes.arrow} ${isNavbar && classes.closeArrowStyle}`}
        onClick={handleArrow}
      >
        <img src={isNavbar ? arrowRight : arrowLeft} alt="arrow" />
      </div>

      <div className={`${classes.sideBarContainer}`}>
        <div className={classes.logo}>
          <Link to="/dashboard">
            {isNavbar ? (
              <div>
                {window.innerWidth > 1199 && (
                  <img src={logoF} alt="logo" className={classes.onelogo} />
                )}
              </div>
            ) : (
              // <img src={logoF} alt="logo" className={classes.onelogo} />
              <img src={logo} alt="logo" className={classes.fulllogo} />
            )}
          </Link>
        </div>
        <div className={`${classes.navLinkSection} sidebar`}>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
              },
            }}
            variant="permanent"
          >
            <List>
              {menuData.map((menuItem, index) => {
                // Check if dropdown should be shown (if any sub-item is accessible)
                const hasAccessibleSubItem = menuItem?.items?.some((item) =>
                  item.roles.includes(userRole)
                );
                return (
                  (menuItem.roles?.includes(userRole) ||
                    hasAccessibleSubItem) && (
                    <Fragment key={index}>
                      {menuItem?.dropdown ? (
                        <>
                          <ListItem
                            button
                            onClick={() => {
                              handleOptionDropClick(menuItem?.title);
                              handleClick(index);
                            }}
                            selected={
                              activeOptionDrop === menuItem?.title.toLowerCase()
                            }
                          >
                            <img
                              src={menuItem?.icon}
                              alt={menuItem?.title}
                              className="sidebarIcon"
                            />
                            <ListItemText primary={menuItem?.title} />
                            {openDropdown === index ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </ListItem>
                          <Collapse
                            in={openDropdown === index}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              {menuItem?.items.map(
                                (item, subIndex) =>
                                  item.roles.includes(userRole) && (
                                    <ListItem
                                      key={subIndex}
                                      button
                                      sx={{ pl: 4 }}
                                      selected={
                                        activeOptionDropItem === item?.link
                                      }
                                      component={Link}
                                      to={item?.link}
                                      onClick={() =>
                                        handleOptionDropClickItem(item?.link)
                                      }
                                    >
                                      <ListItemText primary={item?.title} />
                                    </ListItem>
                                  )
                              )}
                            </List>
                          </Collapse>
                        </>
                      ) : (
                        <ListItem
                          button
                          selected={activeOption === menuItem?.link}
                          component={Link}
                          to={menuItem?.link}
                          onClick={() => {
                            handleOptionClick(menuItem?.link);
                            handleItemClick();
                          }}
                        >
                          <img
                            src={menuItem?.icon}
                            alt={menuItem?.title}
                            className="sidebarIcon"
                          />
                          <ListItemText primary={menuItem?.title} />
                        </ListItem>
                      )}
                    </Fragment>
                  )
                );
              })}
            </List>
          </Drawer>
        </div>
        <div className={`${classes.mainLogout} ${isNavbar && "closeLogout"}`}>
          {/* <div className={`${classes.logoutContent}`}>
          <div className={`${classes.logoutProfileImg}`}>
            <img src={profileimage} alt="profile" />
          </div>
          <div className={`${classes.logoutText}`}>
            <h4> Hassan Malik</h4>
            <p> Product Designer</p>
            <Button onClick={handleLogout} fontsize="0.75rem">
            <img src={logout} alt="logout" /> Logout
            </Button>
          </div>
        </div> */}
          <div className={classes.chef_container}>
            <img src={chef} className={classes.chefimg} />
            <h6>Organize your menus through button bellow</h6>
            <Button
              customClass={classes.menuBtn}
              onClick={handleMenu}
              fontsize="1rem"
              customWidth="145px"
            >
              + Add Menus
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
