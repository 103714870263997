import React, { useState, useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Typography,
  MenuItem,
  // Select as MUISelect,
  Select,
  FormControl,
  Chip,
  Grid,
  OutlinedInput,
  Radio,
  ListItemText,
  Box,
} from "@mui/material";
import Button from "../../components/shared/customBtn";
import { Select as CustomSelect } from "react-dropdown-select";
import classes from "./manageUser.module.scss";
import profile_img from "../../assets/images/placeholderImage.png";
import camera_image from "../../assets/images/Camera.png";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CustomInput from "../../../src/components/shared/customInput";
const options = [
  { id: "General Manager", name: 1 },
  { id: "Sales Person", name: 2 },
  { id: "Order Taker", name: 3 },
  { id: "General Manager", name: 4 },
];
// const storesOptions = [
//   "Gulberg Store Lahore",
//   "DHA Store Lahore",
//   "Johar Town Store Lahore",
// ];
const UserForm = ({
  handleClose,
  onSubmit,
  headTitle,
  textButton,
  isEditing,
}) => {
  // const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState([]);
  const [store, setStore] = useState([]);
  const [userImage, setUserImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(profile_img);
  const fileInputRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPass, setConfirmPass] = useState(false);
  const [selectedStore, setSelectedStore] = useState("");

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    roles: Yup.array().min(1, "Role is required"),
    selectStore: Yup.string().required("Store is required"),
  });
  // const handleChipDelete = () => {
  //   console.log("roleToDelete");
  // };
  const handleImageChange = (event, setFieldValue) => {
    setTimeout(() => {
      const file = event.target.files[0];
      console.log("file", file);
      if (file) {
        setSelectedImage(URL.createObjectURL(file));
        setFieldValue("file", file);
      }
    }, 1000);
  };
  const toggleShowPassword = () => setShowPassword(!showPassword);
  const toggleConfirmPass = () => setConfirmPass(!showConfirmPass);
  const [values, setValues] = useState({
    store: "",
  });

  const handleChangeSelect = (event) => {
    const selectedValue = event.target.value;
    setValues({
      ...values,
      store: selectedValue,
    });
    setSelectedStore(selectedValue); // Update selectedTheme directly
  };
  return (
    <Formik
      initialValues={{
        username: "",
        email: "",
        password: "",
        confirmPassword: "",
        roles: [],
        selectStore: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        console.log("Form Data:", values); // Log the form data here
        onSubmit(values); // Pass the values to the parent component's onSubmit
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        errors,
        touched,
      }) => (
        <Form className={`${classes.parentModal} parent`}>
          <div className={classes.header}>
            <Typography className={classes.title}>{headTitle}</Typography>
            <hr />
          </div>
          <div className={classes.ProfileImg}>
            <img
              src={selectedImage}
              alt="profile_img"
              className={classes.profileImage}
            />
            <span className={classes.uploadIcon}>
              <input
                id="file"
                name="file"
                type="file"
                accept="image/*"
                onChange={(event) => handleImageChange(event, setFieldValue)}
                style={{ display: "none" }}
                ref={fileInputRef}
              />
              <div className="profile-img-cam">
                {camera_image && (
                  <img
                    src={camera_image}
                    alt="camera_image"
                    onClick={() => fileInputRef.current.click()}
                  />
                )}
              </div>
            </span>
          </div>

          <div className={classes.userFormHolder}>
            <div>
              {/* <div className={classes.inputMain}>
                  <label>User Name</label>
                  <Field
                    type="text"
                    name="username"
                    value={values.fname}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    // className={classes.inputField}
                    className={`${classes.inputField} ${
                      errors.username && touched.username ? classes.errorMsgLine : null
                    }`}
                    placeholder="enter username"
                  />
                  <div className={classes.errorMsg}>
                    <ErrorMessage name="username" />
                  </div>
                </div> */}
              <CustomInput
                label="User Name"
                type="text"
                name="username"
                value={values.username}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="enter username"
                touched={touched.username}
                errors={errors.username}
              />
            </div>
            <div>
              {/* <div className={classes.inputMain}>
                  <label>Email Address</label>
                  <Field
                    type="text"
                    name="email"
                    value={values.lname}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    // className={classes.inputField}

                    className={`${classes.inputField} ${
                      errors.email && touched.email ? classes.errorMsgLine : null
                    }`}
                    placeholder="enter email"
                  />
                  <div className={classes.errorMsg}>
                    <ErrorMessage name="email" />
                  </div>
                </div> */}
              <CustomInput
                label="Email Address"
                type="text"
                name="email"
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="enter email"
                touched={touched.email}
                errors={errors.email}
              />
            </div>
            <div>
              {/* <div className={classes.inputMain}>
                  <label>Enter Password</label>
                  <div className={classes.eyeBox}>
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={values.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className={`${classes.inputField} ${
                        errors.password && touched.password
                          ? classes.errorMsgLine
                          : ""
                      }`}
                      placeholder="Enter password"
                    />
                    <IconButton
                      onClick={toggleShowPassword}
                      className={classes.eyeIcon}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </div>
                  <div className={classes.errorMsg}>
                    <ErrorMessage name="password" />
                  </div>
                </div> */}
              <CustomInput
                label="Enter Password"
                type={showPassword ? "text" : "password"}
                name="password"
                value={values.password}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="enter password"
                touched={touched.password}
                errors={errors.password}
                eyeIcon
                setPasswordChange={setShowPassword}
                passwordChange={showPassword}
              />
            </div>
            <div>
              {/* <div className={classes.inputMain}>
                  <label>Confirm Password</label>
                  <div className={classes.eyeBox}>
                    <Field
                      type={showConfirmPass ? "text" : "password"}
                      name="confirmPassword"
                      value={values.confirmPassword}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className={`${classes.inputField} ${
                        errors.confirmPassword && touched.confirmPassword
                          ? classes.errorMsgLine
                          : ""
                      }`}
                      placeholder="Confirm password"
                    />
                    <IconButton
                      onClick={toggleConfirmPass}
                      className={classes.eyeIcon}
                    >
                      {showConfirmPass ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </div>
                  <div className={classes.errorMsg}>
                    <ErrorMessage name="confirmPassword" />
                  </div>
                </div> */}
              <CustomInput
                label="Confirm Password"
                type={showConfirmPass ? "text" : "password"}
                name="confirmPassword"
                value={values.confirmPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="enter confirmPassword"
                touched={touched.confirmPassword}
                errors={errors.confirmPassword}
                eyeIcon
                setPasswordChange={setConfirmPass}
                passwordChange={showConfirmPass}
              />
            </div>
            <div>
              <div className={classes.inputMain}>
                <label>Role</label>
                <CustomSelect
                  name="roles"
                  options={options}
                  className={`${classes.inputFieldRole} ${
                    errors.roles && touched.roles ? classes.errorInput : ""
                  }`}
                  labelField="id"
                  valueField="name"
                  multi
                  // onChange={(value) => {
                  //   setValue(value);
                  //   setFieldValue("roles", value);
                  // }}
                  onChange={(roles) => {
                    setValue(roles);
                    // Map to get only id values
                    setFieldValue("roles", roles);
                    console.log("Selected Roles Data:", roles);
                  }}
                  color="rgb(245, 126, 42)"
                />
                <div className={classes.errorMsg}>
                  <ErrorMessage name="roles" />
                </div>
              </div>
            </div>
            <div>
              {/* <div className={classes.inputMain}>
                  <label>Select Store</label>
                  <Select
                    displayEmpty
                    value={values.store} 
                    onChange={handleChangeSelect}
                    input={<OutlinedInput />}
                    className={classes.inputFieldRole}
                    renderValue={(selected) => {
                      if (!selected) {
                        return (
                          <div>
                            {" "}
                            {selectedStore ? selectedStore : ""}
                          </div>
                        );
                      }
                      return selected;
                    }}
                  >
                    {storesOptions.map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        className="selectBuiltinStyleCutumization"
                      >
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>

                  <div className={classes.errorMsg}>
                    <ErrorMessage name="store" />
                  </div>
                </div> */}
              <CustomInput
                label="Select Store"
                type="select"
                name="selectStore"
                value={values.selectStore}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Select option"
                touched={touched.selectStore}
                errors={errors.selectStore}
                options={[
                  "Gulberg Store Lahore",
                  "DHA Store Lahore",
                  "Johar Town Store Lahore",
                ]}
              />
            </div>
          </div>
          <div className={classes.buttonContainer}>
            <Button
              type="submit"
              customClass={classes.btnAddUsers}
              shadowOrange
              fontsize="0.75rem"
              customWidth="350px"
            >
              {textButton}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default UserForm;
