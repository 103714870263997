import React, { useEffect, useState } from "react";
import TableFilter from "../../components/shared/tableFilter";
import classes from "./CustomerReview.module.scss";
import SlickSlider from "./SlickSlider";
import CustomerImage from "../../assets/images/avatar.png";
import StarIcon from "@mui/icons-material/Star";
import BasicModal from "../../components/shared/basicmodal";
import { Button } from "@mui/material";

const reviewsData = [
  {
    Customerimage: CustomerImage,
    customername: "Tina Haris",
    ReviewDate: "June 4, 2022",
    Review:
      "We recently had dinner with friends at David CC and we all walked away with a great experience. Good food, pleasant environment, personal attention through all the evening. Thanks to the team and we will be back!",
    starIcons: Array(5).fill(<StarIcon className={classes.starIcon} />),
  },
  {
    Customerimage: CustomerImage,
    customername: "Tina Haris",
    ReviewDate: "June 4, 2022",
    Review:
      "We recently had dinner with friends at David CC and we all walked away with a great experience. Good food, pleasant environment, personal attention through all the evening. Thanks to the team and we will be back!",
    starIcons: Array(5).fill(<StarIcon className={classes.starIcon} />),
  },
];

const CustomerReview = () => {
  const [user, setUser] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [userDeep, setUserDeep] = useState([]);
  const [reviews, setReviews] = useState(reviewsData);

  //////For Comments//////////
  const [anchorEl, setAnchorEl] = useState(null); // For dropdown visibility
  const [selectedOption, setSelectedOption] = useState('Show Comments'); // For tracking selected value

 // Handle dropdown button click (open dropdown)
 const handleButtonClick = (event) => {
  setAnchorEl(event.currentTarget); // Opens the dropdown
};

 // Handle dropdown close
 const handleClose = () => {
  setAnchorEl(null); // Closes the dropdown
};

   // Handle dropdown option change (select Show/Hide Comments)
   const handleDropdownChange = (event) => {
    const { value } = event.target;
    setSelectedOption(value); // Set the value when an option is selected
    handleClose();
  };
///////Comments End///////////////////

  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
  };

  useEffect(() => {
    if (searchTerm.length > 2) {
      const filteredCustomers = userDeep.filter((customer) =>
        customer?.name?.toLowerCase().includes(searchTerm)
      );
      setUser(filteredCustomers);
    } else {
      setUser(userDeep);
    }
  }, [searchTerm, userDeep]);

  return (
    <>
      <div className={classes.mainCustomerReview}>
        <div className={classes.fil}>
          <TableFilter
            heading="Reviews"
            tableLength="3"
            searchPlaceholder="Search in Reviews"
            handleSearch={handleSearch}
            buttonTextRight={selectedOption || 'Show Comments'} // Ensures button text is never blank
            iconBtnRight
            datePicker
            handleButtonClick={handleButtonClick} // Pass the handler to open the dropdown
            showDropdown={Boolean(anchorEl)}
            selectedValue={selectedOption}
            handleDropdownChange={handleDropdownChange}
            anchorEl={anchorEl}
            isDropdown={true} // Enable dropdown behavior
          />
        </div>
        <div>
          <SlickSlider />
        </div>
        <div className={classes.RecentReviews}>
          <h3>Recent Reviews</h3>
          <p>Here is customer review about your restaurantLatest</p>
          {reviews.map((rvw, index) => (
            <ReviewItem key={index} review={rvw} />
          ))}
        </div>
      </div>
    </>
  );
};

const ReviewItem = ({ review }) => (
  <div className={classes.reviewItem}>
    <div className={classes.ReviewHeader}>
      <div className={classes.CustomerReviewSection}>
        <img src={review.Customerimage} alt="" />
        <h5>{review.customername}</h5>
      </div>
      <div className={classes.date}>
        <p>{review.ReviewDate}</p>
      </div>
    </div>
    <p>{review.Review}</p>
    <div className={classes.starIcons}>{review.starIcons}</div>
  </div>
);

export default CustomerReview;
