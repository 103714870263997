import React, { useState, useEffect } from "react";
import classes from "./overview.module.scss";
import "./overviewBuiltIn.scss";
import food1 from "../../assets/images/food1.svg";
import food2 from "../../assets/images/food2.svg";
import food3 from "../../assets/images/food3.svg";
import arrowup from "../../assets/images/uparrow.svg";
import downarrow from "../../assets/images/downarrow.svg";
import Stack from "@mui/material/Stack";
import { PieChart } from "@mui/x-charts/PieChart";
import TableFilter from "../../components/shared/tableFilter";
import StarIcon from "@mui/icons-material/Star";
import CustomerImage from "../../assets/images/avatar.png";
import { BarChart, Bar, XAxis, ResponsiveContainer, Tooltip } from "recharts";
const CustomBarShape = (props) => {
  const { x, y, width, height, fill } = props;
  const radius = 10; // Adjust this value to set the border radius

  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={fill}
        rx={radius}
        ry={radius}
      />
    </g>
  );
};
const reviewsData = [
  {
    Customerimage: CustomerImage,
    customername: "Tina Haris",
    ReviewDate: "June 4, 2022",
    Review:
      "We recently had dinner with friends at David CC and we all walked away with a great experience. Good food, pleasant environment, personal attention through all the evening. Thanks to the team and we will be back!",
    starIcons: Array(5).fill(<StarIcon className={classes.starIcon} />),
  },
  {
    Customerimage: CustomerImage,
    customername: "Tina Haris",
    ReviewDate: "June 4, 2022",
    Review:
      "We recently had dinner with friends at David CC and we all walked away with a great experience. Good food, pleasant environment, personal attention through all the evening. Thanks to the team and we will be back!",
    starIcons: Array(5).fill(<StarIcon className={classes.starIcon} />),
  },
];
const Index = () => {
  const [user, setUser] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [reviewDeep, setReviewDeep] = useState([]);
  const [reviews, setReviews] = useState(reviewsData);
  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
  };

  useEffect(() => {
    if (searchTerm.length > 2) {
      const filteredCustomers = reviewDeep.filter((customer) =>
        customer?.name?.toLowerCase().includes(searchTerm)
      );
      setUser(filteredCustomers);
    } else {
      setUser(reviewDeep);
    }
  }, [searchTerm, reviewDeep]);

  const data = [
    {
      item: "Chicken Karahi",
      price: "$70.45",
      productId: "6485156",
      image: food1,
    },
    {
      item: "Paratha Roll",
      price: "$70.45",
      productId: "6485156",
      image: food2,
    },
    { item: "Fresh Naan", price: "$70.45", productId: "6485156", image: food3 },
  ];

  const chartData = [
    { label: "Hardee’s Gulberg", value: 9348319 },
    { label: "Taste of Lahore-Wembley", value: 8348319 },
    { label: "Taste of Lahore-Hounslow", value: 3348319 },
  ];
  const positiveRevData = [
    {
      name: "Monday",
      PR: 88,
    },
    {
      name: "Tuesday",

      PR: 108,
    },
    {
      name: "Wednesday",

      PR: 140,
    },
    {
      name: "Thursday",

      PR: 100,
    },
    {
      name: "Friday",

      PR: 76,
    },
  ];
  const ngativeRevData = [
    {
      name: "Monday",
      NR: 88,
    },
    {
      name: "Tuesday",

      NR: 108,
    },
    {
      name: "Wednesday",

      NR: 140,
    },
    {
      name: "Thursday",

      NR: 100,
    },
    {
      name: "Friday",

      NR: 76,
    },
  ];
  return (
    <div className={classes.overview_section}>
      <h3>Stores</h3>
      <p>Loream Ipsum Text is a simple Dummy Text</p>

      <div className={classes.mainconatiner}>
        <div className={classes.rectangle}>
          <div className={classes.head}>
            <h6>Restaurant Wise Sale</h6>
          </div>
          <div className={classes.saleContainer}>
            <div className={`${classes.divide} customPie`}>
              <Stack direction="row">
                <PieChart
                  series={[
                    {
                      // paddingAngle: 5,
                      innerRadius: 60,
                      outerRadius: 80,
                      data: chartData,
                      cornerRadius: 12,
                    },
                  ]}
                  margin={{ right: 5 }}
                  width={255}
                  height={255}
                  legend={{ hidden: true }}
                />
              </Stack>
            </div>
            <div className={classes.info}>
              <div className={classes.infocontainer}>
                <div className={classes.blackdot}>
                  <span></span>
                  <h6>Hardee’s Gulberg</h6>
                </div>

                <div className={classes.orangedot}>
                  <span></span>
                  <h5>
                    Taste of Lahore-
                    <br />
                    Wembley
                  </h5>
                </div>

                <div className={classes.greydot}>
                  <span></span>
                  <p>
                    Taste of Lahore-
                    <br />
                    Hounslow
                  </p>
                </div>
              </div>

              <div className={classes.third}>
                <h6>$9,348,319</h6>
                <h5>$8,348,319</h5>
                <p>$3,348,319</p>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.top_food_menu}>
          <div className={classes.foodContainer}>
            <div className={classes.head}>
              <h6>Top Food Menu</h6>
            </div>
            <div className={classes.itemh}>
              <span>Item</span>
              <span>Price</span>
              <span>Product ID</span>
            </div>
            {data.map((item, index) => (
              <div key={index} className={classes.item}>
                <div className={classes.dishname}>
                  <img
                    src={item.image}
                    alt={item.item}
                    className={classes.food_image}
                  />
                  <span>{item.item}</span>
                </div>
                <span>{item.price}</span>
                <span>{item.productId}</span>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className={classes.mainconatiner}>
        <div className={classes.rectangle}>
          <div className={classes.reviewContainer}>
            <div className={classes.second}>
              <h6>Positive Review</h6>
              <h5>Oct 31, 2023, 12:02 PM</h5>
              <p>This Month </p>
              <div className={classes.review}>
                <h4>
                  3.678 <span>Reviews</span>
                </h4>
              </div>
              <div className={classes.h}>
                <div className={classes.greencircle}>
                  <img src={arrowup} className={classes.arrow} />
                </div>
                <h6>+15%</h6>
              </div>
            </div>
            <div className={`${classes.ew} customGraph1`}>
              <ResponsiveContainer width={300} height={190}>
                <BarChart
                  width={320}
                  height={190}
                  data={positiveRevData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <Tooltip />
                  <XAxis dataKey="name" fontSize="9px" />
                  <Bar
                    dataKey="PR"
                    fill="#F57E2A"
                    barSize={16}
                    shape={<CustomBarShape />}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>

        <div className={classes.rectangle}>
          <div className={classes.reviewContainer}>
            <div className={classes.second}>
              <h6>Negative Review</h6>
              <h5>Oct 31, 2023, 12:02 PM</h5>
              <p>This Month </p>
              <div className={classes.review}>
                <h4>
                  -365 <span>Reviews</span>
                </h4>
              </div>
              <div className={classes.h}>
                <div className={classes.redcircle}>
                  <img src={downarrow} className={classes.arrow} />
                </div>
                <h6>+15%</h6>
              </div>
            </div>
            <div className={`${classes.ew} customGraph1`}>
              <ResponsiveContainer width={300} height={190}>
                <BarChart
                  width={320}
                  height={190}
                  data={ngativeRevData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <Tooltip />
                  <XAxis dataKey="name" fontSize="9px" />
                  <Bar
                    dataKey="NR"
                    fill="#E00000"
                    barSize={18}
                    shape={<CustomBarShape />}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.reviewMain}>
        <TableFilter
          heading="Recent Reviews"
          tableLength="101"
          searchPlaceholder="Search in Reviews"
          handleSearch={handleSearch}
          datePicker
        />
        {reviews.map((rvw, index) => (
          <ReviewItem key={index} review={rvw} />
        ))}
      </div>
    </div>
  );
};
const ReviewItem = ({ review }) => (
  <div className={classes.reviewItem}>
    <div className={classes.ReviewHeader}>
      <div className={classes.CustomerReviewSection}>
        <img src={review.Customerimage} alt="" />
        <h5>{review.customername}</h5>
      </div>
      <div className={classes.date}>
        <p>{review.ReviewDate}</p>
      </div>
    </div>
    <p>{review.Review}</p>
    <div className={classes.starIcons}>{review.starIcons}</div>
  </div>
);
export default Index;
